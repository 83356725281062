import { Grid, Typography } from "@mui/material";
import React from "react";
import QuestionTextBox from "../../TextEditor/QuestionTextBox";

const QuestionTypeEssay = ({
  commentAns,
  setCommentAns,
  questionData,
  optionValue,
  setOptionValue,
}) => {
  return (
    <>
      <Grid
        container
        sx={{
          margin: 2,
        }}
      >
        <Grid xs={12}>
          <Typography>Essay :</Typography>
        </Grid>
        <Grid
          xs={12}
          sx={{
            margin: 2,
          }}
        >
          <QuestionTextBox
            value={commentAns}
            setValue={setCommentAns}
          ></QuestionTextBox>
        </Grid>
      </Grid>
    </>
  );
};

export default QuestionTypeEssay;
