import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React from "react";

const QuestionTypeTrueorFalse = ({
  commentAns,
  setCommentAns,
  questionData,
  optionValue,
  setOptionValue,
}) => {
  return (
    <>
      <Grid container>
        <Grid xs={12}>
          <RadioGroup
            value={commentAns}
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={(e) => {
              setCommentAns(e.target.value);
            }}
          >
            <FormControlLabel
              value={true}
              labelPlacement="start"
              label={<Typography>True</Typography>}
              control={
                <Radio
                  size="small"
                  sx={{
                    "&.Mui-checked": { color: "#6B74D8" },
                  }}
                  style={{
                    marginRight: 8,
                    marginLeft: -5,
                  }}
                />
              }
            />
            <FormControlLabel
              value={false}
              labelPlacement="start"
              label={<Typography>False</Typography>}
              control={
                <Radio
                  size="small"
                  sx={{
                    "&.Mui-checked": { color: "#6B74D8" },
                  }}
                  style={{
                    marginRight: 8,
                    marginLeft: -5,
                  }}
                />
              }
            />
          </RadioGroup>
        </Grid>
      </Grid>
    </>
  );
};

export default QuestionTypeTrueorFalse;
