import { Grid, TextField, Typography } from "@mui/material";
import React from "react";

const QuestionTypeFillups = ({ commentAns, setCommentAns }) => {
  return (
    <>
      <Grid container>
        <Grid xs={12} sx={{ margin: 5 }}>
          <TextField
            value={commentAns}
            id="outlined-textarea"
            label="Answer :"
            multiline
            fullWidth
            rows={3}
            onChange={(e) => {
              setCommentAns(e.target.value);
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default QuestionTypeFillups;
