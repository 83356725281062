import React, { useEffect } from "react";
import QuestionTypeCheckBox from "./QuestionTypeCheckBox";
import QuestionTypeEssay from "./QuestionTypeEssay";
import QuestionTypeFillups from "./QuestionTypeFillups";
import QuestionTypeRadio from "./QuestionTypeRadio";
import QuestionTypeTrueorFalse from "./QuestionTypeTrueorFalse";
import QuestionTypeYesorNo from "./QuestionTypeYesorNo";
import QuestionTypeMatching from "./QuestionTypeMatching";

const QuestionType = ({
  commentAns,
  setCommentAns,
  questionData,
  optionValue,
  setOptionValue,
  currentQuestion,
  rfInstance,
  setRfInstance,
  nodes,
  setNodes,
  onNodesChange,
  edges,
  setEdges,
  onEdgesChange,
}) => {
  useEffect(() => {
    console.log("prr");
    console.log(questionData);
  }, [questionData, currentQuestion]);

  const questionTypeMethod = () => {
    switch (questionData?.questiontypes) {
      case 1:
        return (
          <QuestionTypeRadio
            questionData={questionData}
            optionValue={optionValue}
            setOptionValue={setOptionValue}
          ></QuestionTypeRadio>
        );

      case 2:
        return (
          <QuestionTypeFillups
            commentAns={commentAns}
            setCommentAns={setCommentAns}
            questionData={questionData}
            optionValue={optionValue}
            setOptionValue={setOptionValue}
          ></QuestionTypeFillups>
        );

      case 3:
        return (
          <QuestionTypeCheckBox
            commentAns={commentAns}
            setCommentAns={setCommentAns}
            questionData={questionData}
            optionValue={optionValue}
            setOptionValue={setOptionValue}
          ></QuestionTypeCheckBox>
        );

      case 4:
        return (
          <QuestionTypeTrueorFalse
            commentAns={commentAns}
            setCommentAns={setCommentAns}
            questionData={questionData}
            optionValue={optionValue}
            setOptionValue={setOptionValue}
          ></QuestionTypeTrueorFalse>
        );

      case 5:
        return (
          <QuestionTypeYesorNo
            commentAns={commentAns}
            setCommentAns={setCommentAns}
            questionData={questionData}
            optionValue={optionValue}
            setOptionValue={setOptionValue}
          ></QuestionTypeYesorNo>
        );

      case 6:
        return (
          <QuestionTypeEssay
            commentAns={commentAns}
            setCommentAns={setCommentAns}
            questionData={questionData}
            optionValue={optionValue}
            setOptionValue={setOptionValue}
          ></QuestionTypeEssay>
        );

      case 7:
        return (
          <QuestionTypeMatching
            commentAns={commentAns}
            setCommentAns={setCommentAns}
            questionData={questionData}
            optionValue={optionValue}
            setOptionValue={setOptionValue}
            rfInstance={rfInstance}
            currentQuestion={currentQuestion}
            setRfInstance={setRfInstance}
            nodes={nodes}
            setNodes={setNodes}
            onNodesChange={onNodesChange}
            edges={edges}
            setEdges={setEdges}
            onEdgesChange={onEdgesChange}
          ></QuestionTypeMatching>
        );
      default:
        break;
    }
  };

  return <>{questionTypeMethod()}</>;
};

export default QuestionType;
