import { ThemeProvider } from "@mui/material";
import "./App.css";
import { theme } from "./theme";
import MainRouter from "./Router/MainRouter";
import store from "./stores/store";
import { Provider } from "react-redux";

function App() {
  return (
    <div>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <MainRouter></MainRouter>
        </ThemeProvider>
      </Provider>
    </div>
  );
}

export default App;
