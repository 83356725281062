import React, { useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import hljs from "highlight.js";
import "highlight.js/styles/base16/darcula.css";
import { Button } from "@mui/material";

import ImageResize from "quill-image-resize-module-react";

Quill.register("modules/imageResize", ImageResize);

hljs.configure({
  languages: ["javascript", "ruby", "python", "rust", "java"],
});

const modules = {
  syntax: {
    highlight: (text) => hljs.highlightAuto(text).value,
  },
  toolbar: [
    ["code-block"],
    [{ script: "sub" }, { script: "super" }],
    ["link", "image", "formula"],
  ],
  imageResize: {
    parchment: Quill.import("parchment"),
    modules: ["Resize", "DisplaySize"],
  },
};

const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "background",
  "code-block",
  "script",
];

function OptionsTextBox({
  label,
  index,
  optionid,
  optionsData,
  setoptionsData,
}) {
  return (
    <div className="text-editor">
      <ReactQuill
        theme="snow"
        modules={modules}
        formats={formats}
        readOnly={false}
        defaultValue={optionsData[index]?.optiondescription}
        onChange={(content, delta, source, editor) => {
          let temp = {
            optionlable: label,
            optionid: optionsData[index]?.optionid,
            optiondescription: editor.getContents(),
          };

          let temparray = [...optionsData];

          temparray[index] = temp;

          setoptionsData(temparray);
        }}
      ></ReactQuill>
    </div>
  );
}

export default OptionsTextBox;
