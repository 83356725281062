import API from "./API";

export const getCourseResourcesAPI = (courseId) => {
  return API.get(`/course/getallresource/${courseId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const addCourseResourceAPI = (data) => {
  return API.post(`/course/addresource`, data, {
    headers: {
      "Content-Type":
        "multipart/form-data; boundary=----WebKitFormBoundaryTtTi35D4MiWOz5uq",
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteCourseResourcesAPI = (resourceId) => {
  return API.delete(`/course/deleteresource/${resourceId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
