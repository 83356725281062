import { configureStore, combineReducers } from "@reduxjs/toolkit";
import dashboardSlice from "../slices/dashboardSlice";
import quizSlices from "../slices/quizSlices";
import notificationSlice from "../slices/notificationSlice";
import loginSlice from "../slices/loginSlice";
import questionSlice from "../slices/questionSlice";
import optionSlice from "../slices/optionSlice";
import testSlice from "../slices/testSlice";
import answerSlice from "../slices/answerSlice";
import courseSlice from "../slices/courseSlice";
import syllabusSlice from "../slices/syllabusSlice";
import topicSlice from "../slices/topicSlice";
import authSlice from "../slices/authSlice";
import wishListSlice from "../slices/wishListSlice";
import paymentSlice from "../slices/paymentSlice";
import purchaseSlice from "../slices/purchaseSlice";
import cartSlice from "../slices/cartSlice";
import qnaSlice from "../slices/qnaSlice";
import rnrSlice from "../slices/rnrSlice";
import courseResourceSlice from "../slices/courseResourceSlice";
import marketingSlice from "../slices/marketingSlice";
import settingSlice from "../slices/settingSlice";
import userSlice from "../slices/userSlice";
import eventSlice from "../slices/eventSlice";

const combinedReducer = combineReducers({
  course: courseSlice,
  auth: authSlice,
  quiz: quizSlices,
  notification: notificationSlice,
  login: loginSlice,
  question: questionSlice,
  options: optionSlice,
  test: testSlice,
  answer: answerSlice,
  syllabus: syllabusSlice,
  topic: topicSlice,
  wishlist: wishListSlice,
  payment: paymentSlice,
  purchase: purchaseSlice,
  qna: qnaSlice,
  rnr: rnrSlice,
  marketing: marketingSlice,
  event: eventSlice,
  courseResource: courseResourceSlice,
  cart: cartSlice,
  setting: settingSlice,
  user: userSlice,
  dashboard: dashboardSlice,
});

const rootReducer = (state, action) => {
  if (action.type === "login/setLoginFlag") {
    // check for action type
    state = undefined;
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
});

export default store;
