import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";
import ViewTextBox from "../../TextEditor/ViewTextBox";

const QuestionTypeRadio = ({ questionData, optionValue, setOptionValue }) => {
  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        value={optionValue ? optionValue : ""}
        onChange={(event) => {
          setOptionValue(event.target.value);
        }}
      >
        <ul>
          {questionData?.options?.map((val, index) => {
            return (
              <li key={index}>
                <div className="options">
                  {/* <p>{val?.optionlable}&nbsp;:&nbsp;</p> */}
                  <FormControlLabel
                    style={{
                      // marginTop: -35,
                      marginRight: 2,
                    }}
                    value={val?.optionid}
                    control={
                      <Radio
                        size="small"
                        sx={{
                          "&.Mui-checked": {
                            color: "#6B74D8",
                          },
                        }}
                      />
                    }
                    label={`${val?.optionlable} : `}
                    labelPlacement="start"
                  />

                  <ViewTextBox
                    value={
                      val?.optiondescription &&
                      JSON.parse(val?.optiondescription)
                    }
                  ></ViewTextBox>
                </div>
              </li>
            );
          })}
        </ul>
      </RadioGroup>
    </FormControl>
  );
};

export default QuestionTypeRadio;
