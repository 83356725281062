import { Box, Card, Typography } from "@mui/material";
import React from "react";
import Pdf from "react-to-pdf";
import { useRef } from "react";
import Bar from "../../assets/images/barWith.svg";
import Scroll from "../../assets/images/Scroll.svg";
import Logo from "../../assets/Logo/logo.png";

export default function CertificateDesignTwo({
  refi,
  userName,
  certificateNumber,
}) {
  const ref = useRef();

  const options = {
    orientation: "landscape",
    unit: "in",
    format: [8.271, 5.833],
  };

  return (
    <Box>
      {/* <Pdf targetRef={ref} filename="document.pdf" options={options} scale={1}>
        {({ toPdf }) => (
            <button onClick={toPdf} className="button">
                Download PDF
            </button>
        )}
    </Pdf> */}
      <Box ref={refi} sx={{ width: "690px", height: "500px", p: "24px 54px" }}>
        <Box
          sx={{
            borderColor: "#E9326B",
            borderStyle: "double",
            height: "100%",
            textAlign: "center",
            position: "relative",
          }}
        >
          <img src={Logo} width="100px" height="100px" />
          <Box sx={{ position: "relative" }}>
            <img src={Bar} width="100%" height="100px" />
            <Typography
              variant="h1"
              sx={{
                fontSize: "28px",
                fontWeight: 700,
                color: "#fff",
                mt: "32px",
                position: "absolute",
                top: "22px",
                left: 0,
                right: 0,
                margin: "0 auto",
              }}
            >
              CERTIFICATE OF COMPLETION
            </Typography>
          </Box>

          <Typography
            variant="h4"
            sx={{
              fontSize: "16px",
              fontWeight: 400,
              color: "#3A3A3A",
              mt: "0px",
            }}
          >
            This certificate is proudly presented to
          </Typography>

          <Typography
            variant="h2"
            sx={{
              fontSize: "24px",
              fontWeight: 400,
              color: "#3A3A3A",
              mt: "54px",
            }}
          >
            {userName}
          </Typography>

          <Typography
            variant="body1"
            sx={{
              fontSize: "10px",
              fontWeight: 400,
              color: "#3A3A3A",
              mt: "32px",
              mx: "176px",
            }}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tellus
            nulla tellus, vitae vitae amet fusce. Nam nec aliquet nunc vitae
            eget mattis. Sem nec ut nisi, et malesuada sapien. Lorem ipsum dolor
            sit amet, consectetur adipiscing elit.
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              mx: "48px",
              mt: "80px",
            }}
          >
            <Typography
              variant="body1"
              sx={{ fontSize: "10px", fontWeight: 600 }}
            >
              Signature of a Teacher
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontSize: "10px", fontWeight: 600 }}
            >
              Date
            </Typography>
          </Box>
          <Typography
            sx={{
              position: "absolute",
              bottom: "20px",
              fontSize: "12px",
              display: "flex",
              width: "100%",
              justifyContent: "center",
            }}
          >
            {certificateNumber?.split("-")[0] +
              " - " +
              certificateNumber?.split("-")[1]}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
