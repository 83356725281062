import { Box, Button, Grid, Input, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useCallback } from "react";
import {
  Position,
  ReactFlow,
  addEdge,
  useEdgesState,
  Panel,
  useNodesState,
  useReactFlow,
} from "reactflow";
import "reactflow/dist/style.css";
import "./Matching/Matching.css";
import ButtonEdge from "./Matching/ButtonEdge";
import { useEffect } from "react";

import ViewTextBox from "../../TextEditor/ViewTextBox";

let id = 2;
const getId = () => `${id++}`;
const edgeTypes = {
  buttonedge: ButtonEdge,
};
function QuestionTypeMatching({
  correctAns,
  optionsLable,
  questionData,
  optionsData,
  setoptionsData,
  setCorrectAns,
  setOptionsLable,
  rfInstance,
  currentQuestion,
  setRfInstance,
  nodes,
  setNodes,
  onNodesChange,
  edges,
  setEdges,
  onEdgesChange,
}) {
  useEffect(() => {
    console.log("testtttt");
    console.log("testtttt");
    onRestore();
  }, [questionData, currentQuestion]);

  const onConnect = useCallback(
    (params) => {
      setEdges((eds) => addEdge({ ...params, type: "buttonedge" }, eds));
    },
    [setEdges]
  );

  const onRestore = useCallback(() => {
    const restoreFlow = async () => {
      const flow = JSON.parse(questionData?.answer);

      if (flow) {
        console.log(flow?.nodes);
        const temp = flow.nodes;

        const initialNodes = [
          {
            id: "1",
            style: {
              width: 150,
              padding: 0,
            },
            type: "input",
            data: {
              label: (
                <>
                  <ViewTextBox value={temp[0]?.data?.label} />
                </>
              ),
            },
            position: { x: 0, y: temp[0]?.position?.y },
            sourcePosition: Position.Right,
          },
          {
            id: "2",
            style: {
              width: 150,
              padding: 0,
            },
            type: "input",
            data: {
              label: (
                <>
                  <ViewTextBox value={temp[1]?.data?.label} />
                </>
              ),
            },
            position: { x: 0, y: temp[1]?.position?.y },
            sourcePosition: Position.Right,
          },
          {
            id: "3",
            style: {
              width: 150,
              padding: 0,
            },
            type: "input",
            data: {
              label: (
                <>
                  <ViewTextBox value={temp[2]?.data?.label} />
                </>
              ),
            },
            position: { x: 0, y: temp[2]?.position?.y },
            sourcePosition: Position.Right,
          },
          {
            id: "4",
            style: {
              width: 150,
              padding: 0,
            },
            type: "input",
            data: {
              label: (
                <>
                  <ViewTextBox value={temp[3]?.data?.label} />
                </>
              ),
            },
            position: { x: 0, y: temp[3]?.position?.y },
            sourcePosition: Position.Right,
          },
          {
            id: "5",
            style: {
              width: 150,
              padding: 0,
            },
            type: "output",
            data: {
              label: (
                <>
                  <ViewTextBox value={temp[4]?.data?.label} />
                </>
              ),
            },
            position: { x: 250, y: temp[4]?.position?.y },
            targetPosition: Position.Left,
            // style: {
            //   background: "#D6D5E6",
            //   color: "#333",
            //   border: "1px solid #222138",
            //   width: 180,
            // },
          },
          {
            id: "6",
            style: {
              width: 150,
              padding: 0,
            },
            type: "output",
            data: {
              label: (
                <>
                  <ViewTextBox value={temp[5]?.data?.label} />
                </>
              ),
            },
            position: { x: 250, y: temp[5]?.position?.y },
            targetPosition: Position.Left,
          },
          {
            id: "7",
            style: {
              width: 150,
              padding: 0,
            },
            type: "output",
            data: {
              label: (
                <>
                  <ViewTextBox value={temp[6]?.data?.label} />
                </>
              ),
            },
            position: { x: 250, y: temp[6]?.position?.y },
            targetPosition: Position.Left,
          },
          {
            id: "8",
            style: {
              width: 150,
              padding: 0,
            },
            type: "output",
            data: {
              label: (
                <>
                  <ViewTextBox value={temp[7]?.data?.label} />
                </>
              ),
            },
            position: { x: 250, y: temp[7]?.position?.y },
            targetPosition: Position.Left,
          },
        ];
        setNodes(initialNodes);
      }
    };

    restoreFlow();
  }, [setNodes]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography>Matching :</Typography>
        </Grid>
      </Grid>

      <div
        style={{
          display: "flex",
          flexFlow: "column",
          width: "100%",
          height: "50vh",
          overflowY: "auto",
        }}
      >
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onInit={setRfInstance}
          edgeTypes={edgeTypes}
          onEdgeClick={(_, edge) => {
            setEdges((eds) => eds.filter((e) => e.id !== edge.id));
          }}
          fitView
          snapToGrid
          preventScrolling={false}
          attributionPosition="bottom-left"
          nodesDraggable={false}
          zoomOnScroll={false}
          zoomOnPinch={false}
          panOnDrag={false}
          // elementsSelectable={false}
          zoomOnDoubleClick={false}
          selectNodesOnDrag={false}
          className="touchdevice-flow"
          style={{ maxHeight: "100%", overflowY: "auto", margin: "1.5%" }}
        >
          {/* <Panel position="top-right">
            <Button
            onClick={() => {
              setNodes((nds) => {
                console.log(nds);
                const temp = nds[nds.length - 1];
                console.log(temp);
                let x = 0;
                let y = temp.position.y + temp.height + 10;

                const newNode = {
                  id: getId(),
                  type: "input",
                  position: {
                    x: x,
                    y: temp[0]?.position?.y                  },
                  data: {
                    label: (
                      <>
                        <TextField variant="standard" size="small"></TextField>
                      </>
                    ),
                  },
                  sourcePosition: Position.Left,
                };
                console.log(newNode);

                return nds.concat(newNode);
              });
            }}
          >
            Add Node
          </Button>
            <Button
              onClick={() => {
                onSave();
              }}
            >
              save
            </Button>
          </Panel> */}
        </ReactFlow>
      </div>
    </>
  );
}

export default QuestionTypeMatching;
