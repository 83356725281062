import { Container } from "@mui/system";
import React from "react";
import Thanks from "../assets/images/thanks.png";
import { Box, Button } from "@mui/material";
import { Heading2 } from "../components/Common/HeadingText";
import { useNavigate } from "react-router-dom";

export default function TransactionFailed() {
  const navigate = useNavigate();

  const viewMyCourse = () => {
    navigate("/dashboard");
  };
  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          height: "80vh",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "40px",
        }}
      >
        <img src={Thanks} />
        <Heading2 text="Transaction Failed, please try again later or contact support!!" />
        <Button
          onClick={viewMyCourse}
          sx={{
            fontSize: "14px",
            color: "#6B74D8",
            background: "transparent",
            ["&:hover"]: { background: "transparent" },
            fontWeight: 700,
            p: 0,
          }}
        >
          Go to courses
        </Button>
      </Box>
    </Container>
  );
}
