import { Box, Card, Typography } from "@mui/material";
import React from "react";
import Pdf from "react-to-pdf";
import { useRef } from "react";
import Bar from "../../assets/images/Bar.svg";
import Logo from "../../assets/Logo/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

export default function CertificateDesignOne({
  refi,
  userName,
  certificateNumber,
}) {
  const ref1 = useRef();
  // // const options = {
  // //   orientation: "landscape",
  // //   unit: "in",
  // //   format: [8.271, 5.833],
  // // };

  return (
    <Box>
      {/* <Pdf targetRef={ref} filename="document.pdf" options={options} scale={1}>
        {({ toPdf }) => (
            <button onClick={toPdf} className="button">
                Download PDF
            </button>
        )}
    </Pdf> */}
      <Box
        ref={refi}
        sx={{ width: "794px", height: "560px", backgroundColor: "#E9326B" }}
      >
        <Card
          sx={{
            mx: "8px",
            background: "#fffff",
            border: 0,
            borderRadius: 0,
            height: "100%",
            boxShadow: "none",
            textAlign: "center",
            position: "relative",
          }}
        >
          <Box sx={{ mt: "22px" }}>
            <img src={Logo} width="100px" height="100px" />
            <Typography
              variant="h1"
              sx={{
                fontSize: "28px",
                fontWeight: 700,
                color: "#3A3A3A",
                mt: "32px",
              }}
            >
              CERTIFICATE OF COMPLETION
            </Typography>

            <Typography
              variant="h4"
              sx={{
                fontSize: "16px",
                fontWeight: 400,
                color: "#3A3A3A",
                mt: "12px",
              }}
            >
              This certificate is proudly presented to
            </Typography>

            <Typography
              variant="h2"
              sx={{
                fontSize: "24px",
                fontWeight: 400,
                color: "#3A3A3A",
                mt: "54px",
              }}
            >
              {userName}
            </Typography>

            <Typography
              variant="body1"
              sx={{
                fontSize: "10px",
                fontWeight: 400,
                color: "#3A3A3A",
                mt: "32px",
                mx: "176px",
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tellus
              nulla tellus, vitae vitae amet fusce. Nam nec aliquet nunc vitae
              eget mattis. Sem nec ut nisi, et malesuada sapien. Lorem ipsum
              dolor sit amet, consectetur adipiscing elit.
            </Typography>
            <img
              src={Bar}
              width="60px"
              height="60px"
              style={{ marginTop: "36px" }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              mx: "80px",
              mt: "20px",
            }}
          >
            <Typography
              variant="body1"
              sx={{ fontSize: "10px", fontWeight: 600 }}
            >
              Signature of a Teacher
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontSize: "10px", fontWeight: 600 }}
            >
              Date
            </Typography>
          </Box>
          <Typography
            sx={{
              position: "absolute",
              bottom: "20px",
              fontSize: "12px",
              display: "flex",
              width: "100%",
              justifyContent: "center",
            }}
          >
            {certificateNumber?.split("-")[0] +
              " - " +
              certificateNumber?.split("-")[1]}
          </Typography>
        </Card>
      </Box>
    </Box>
  );
}
