import API from "./API";

export const updateChangePasswordAPI = (payload) => {
  return API.put("/changepassword", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateUserDetailsAPI = (payload) => {
  return API.put("/userdetails", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const addPasswordAPI = (payload) => {
  return API.put("/addpassword", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
