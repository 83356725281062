import API from "./API";

export const addPurchaseCourseAPI = (payload) => {
  return API.post(
    `/purchasecourse?courseId=${payload?.courseId}&session_id=${payload?.session_id}`
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getPurchaseCourseAPI = () => {
  return API.get("/purchasecourse")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const enrollCourseAPI = (payload) => {
  return API.post(`/enrollcourse?courseId=${payload?.courseId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
