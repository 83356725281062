import API from "./API";

export const getWishlistAPI = () => {
  return API.get("/whishlist")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const addWishlistAPI = (id) => {
  return API.post(`/whishlist?courseId=${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const deleteWishlistAPI = (id) => {
  return API.delete(`/whishlist?courseId=${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};
