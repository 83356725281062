import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addEventAPI,
  addEventImgAPI,
  deleteEventAPI,
  getEventAPI,
  getEventBlogAPI,
  updateEventAPI,
} from "../api/EventApi";

const initialState = {
  events: [],
  event: {},
  eventImg: null,

  loading: false,
  addnUpEventLoading: null,
};

export const getEvent = createAsyncThunk(
  "event/getEvent",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getEventAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const getSingleEvent = createAsyncThunk(
  "blog/getSingleEvent",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getEventBlogAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const addEvent = createAsyncThunk(
  "marketing/addEvent",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await addEventAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const updateEvent = createAsyncThunk(
  "marketing/updateEvent",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await updateEventAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const deleteEvent = createAsyncThunk(
  "marketing/deleteEvent",
  async (id, { rejectWithValue }) => {
    try {
      const response = await deleteEventAPI(id);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const addEventImg = createAsyncThunk(
  "marketing/addEventImg",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await addEventImgAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

const eventSlice = createSlice({
  name: "event",
  initialState,

  extraReducers: (builder) => {
    //GET event
    builder.addCase(getEvent.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(getEvent.fulfilled, (state, action) => {
      state.events = action.payload?.response;
      state.loading = true;
    });
    builder.addCase(getEvent.rejected, (state) => {
      state.loading = false;
    });

    //GET event
    builder.addCase(getSingleEvent.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(getSingleEvent.fulfilled, (state, action) => {
      state.event = action.payload?.response;
      state.loading = true;
    });
    builder.addCase(getSingleEvent.rejected, (state) => {
      state.loading = false;
    });

    //add event
    builder.addCase(addEvent.pending, (state) => {
      state.addnUpEventLoading = false;
    });
    builder.addCase(addEvent.fulfilled, (state, action) => {
      state.event = action.payload?.response;
      state.events.events
        ? state.events.events.push(action.payload.response)
        : (state.events.events = [action.payload.response]);
      state.addnUpEventLoading = true;
    });
    builder.addCase(addEvent.rejected, (state) => {
      state.addnUpEventLoading = null;
    });

    //update event
    builder.addCase(updateEvent.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(updateEvent.fulfilled, (state, action) => {
      state.event = action.payload.response;
      const { arg } = action.meta;
      if (arg) {
        state.events = {
          currentPage: state.events.currentPage,
          totalItems: state.events.totalItems,
          totalPages: state.events.totalPages,
          events: state.events.events.map((item) =>
            Number(item.eventId) === Number(arg.eventId)
              ? {
                  blogUrl: arg.blogUrl,
                  EventDescription: arg.EventDescription
                    ? arg.EventDescription
                    : "",
                  eventId: arg.eventId,
                  eventTitle: arg.eventTitle,
                  featuredImage: arg.featuredImage,
                  thumbnailImage: arg.thumbnailImage,
                  metaDescription: arg.metaDescription,
                  metaKeyWord: arg.metaKeyWord,
                  eventTags: arg.eventTags,
                }
              : item
          ),
        };
      }
      state.loading = true;
    });
    builder.addCase(updateEvent.rejected, (state) => {
      state.loading = false;
    });

    //delete event
    builder.addCase(deleteEvent.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteEvent.fulfilled, (state, action) => {
      state.event = action.payload;
      const { arg } = action.meta;
      if (arg) {
        state.events.events = state.events.events.filter(
          (item) => item.eventId !== arg
        );
      }
      state.loading = true;
    });
    builder.addCase(deleteEvent.rejected, (state) => {
      state.loading = false;
    });

    //add event img
    builder.addCase(addEventImg.pending, (state) => {
      state.eventImg = false;
    });
    builder.addCase(addEventImg.fulfilled, (state, action) => {
      state.eventImg = action.payload?.response;
      state.addnUpEventLoading = true;
    });
    builder.addCase(addEventImg.rejected, (state) => {
      state.eventImg = null;
    });
  },
});

export default eventSlice.reducer;
