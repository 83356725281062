import API from "./API";

export const getTopicAPI = (id) => {
  return API.get(`/topic/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getTopicUserAPI = (payload) => {
  return API.get(`/topic/${payload.courseId}/${payload.syllabusId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const addTopicAPI = (payload) => {
  return API.post("/topic", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const addMarkCompleteAPI = (data) => {
  return API.post(`/markascompleted/${data.courseId}/${data.topicId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateTopicAPI = (payload) => {
  return API.put("/topic", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const topicRangeAPI = (payload) => {
  return API.post("/topicarrange", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteTopicAPI = (id) => {
  return API.delete(`/topic/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const addVideoAPI = (payload) => {
  const formData = new FormData();
  // Object.keys(payload).map((key) => formData.append(key, payload[key]));
  // for (var pair of formData.entries()) {
  //   console.log("pair", pair[0] + ", " + pair[1]);
  // }
  formData.append("videoid", payload.videoid);
  formData.append("file", payload.file);
  return API.post("/video", formData, {
    headers: {
      "Content-Type":
        "multipart/form-data; boundary=----WebKitFormBoundaryTtTi35D4MiWOz5uq",
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const addAudioAPI = (payload) => {
  const formData = new FormData();
  // Object.keys(payload).map((key) => formData.append(key, payload[key]));
  // for (var pair of formData.entries()) {
  //   console.log("pair", pair[0] + ", " + pair[1]);
  // }
  formData.append("audioId", payload.audioid);
  formData.append("file", payload.file);

  return API.post("/audio", formData, {
    headers: {
      "Content-Type":
        "multipart/form-data; boundary=----WebKitFormBoundaryTtTi35D4MiWOz5uq",
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const addVideoThumbnailAPI = (payload) => {
  const formData = new FormData();
  formData.append("videoid", payload.videoid);
  formData.append("file", payload.file);
  return API.post("/videothumbnail", formData, {
    headers: {
      "Content-Type":
        "multipart/form-data; boundary=----WebKitFormBoundaryTtTi35D4MiWOz5uq",
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
