import API from "./API";

export const getAllRnRAPI = () => {
  return API.get(`/ratingandreview/`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getRnRAPI = (courseId) => {
  return API.get(`/ratingandreview/${courseId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const addRnRAPI = (payload) => {
  return API.post(`/ratingandreview`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteRnRAPI = (ratingId) => {
  return API.delete(`/ratingandreview/${ratingId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
