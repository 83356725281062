import API from "./API";

export const getAllQuizAPI = (payload) => {
  return API.get("/quiz")
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const getAllQuizByUserAPI = (payload) => {
  return API.get(`/quizforuser/${payload}`)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const addQuizAPI = (payload) => {
  return API.post("/quiz", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteQuizAPI = (payload) => {
  return API.delete(`/quiz/${payload}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateQuizAPI = (payload) => {
  return API.put("/quiz", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateQuizPublishAPI = (payload) => {
  return API.put(`/quizpublish/${payload?.publishid}/${payload?.quizid}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
