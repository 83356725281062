import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  type: "success",
  Notification: false,
  message: null,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    showNotification: (state, action) => {
      state.Notification = true;
      state.type = action.payload.type;
      state.message = action.payload.message;
    },
    offNotification: (state) => {
      state.Notification = false;
      state.message = null;
    },
  },
});

export const { showNotification, offNotification } = notificationSlice.actions;
export default notificationSlice.reducer;
