import React, { useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import hljs from "highlight.js";
import "highlight.js/styles/base16/darcula.css";
import { Button } from "@mui/material";
import ImageResize from "quill-image-resize-module-react";

Quill.register("modules/imageResize", ImageResize);

hljs.configure({
  languages: ["javascript", "ruby", "python", "rust", "java"],
});

const modules = {
  syntax: {
    highlight: (text) => hljs.highlightAuto(text).value,
  },
  toolbar: [
    [{ font: [] }, { size: [] }],
    ["bold", "italic", "underline", "strike", "font"],
    ["code-block"],
    [{ script: "sub" }, { script: "super" }],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    [{ background: [] }, { color: [] }],
    ["link", "image", "formula"],
  ],
  imageResize: {
    parchment: Quill.import("parchment"),
    modules: ["Resize", "DisplaySize"],
  },
};

const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "background",
  "code-block",
  "script",
];

function QuestionTextBox({ addQuestionMethod, value, setValue, readonly }) {
  return (
    <div
      className="text-editor"
      style={{
        zIndex: 100000,
      }}
    >
      <ReactQuill
        theme="snow"
        modules={modules}
        formats={formats}
        readOnly={readonly ? readonly : false}
        value={value}
        onChange={(content, delta, source, editor) => {
          setValue(editor.getContents());
          //console.log(editor.getContents());
        }}
      ></ReactQuill>
    </div>
  );
}

export default QuestionTextBox;
