import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";

import Logo from "../assets/Logo/logo.jpg";
import AvatorIcon from "../assets/Icon/AvatorIcon.svg";
import Searchicon from "../assets/Icon/SearchIcon.svg";
import NotificationIcon from "../assets/Icon/NotificationIcon.svg";
import {
  Autocomplete,
  ImageListItem,
  Link,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuIcon from "@mui/icons-material/Menu";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
// import { getSearchCourse } from "../slices/courseSlice";
import DashboardIcon from "@mui/icons-material/Dashboard";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import LogoutIcon from "@mui/icons-material/Logout";

const navbardata = [
  { title: "Home", Link: "/dashboard" },
  { title: "About", Link: "https://www.amildham.com/about" },
  { title: "Blog", Link: "https://www.amildham.com/blog" },
  { title: "Contact", Link: "https://www.amildham.com/contact" },
  { title: "Membership", Link: "/membership" },
];
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "300px",
  flexGrow: 1,
  [theme.breakpoints.up("md")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "#363030AD",
  height: "35.12px",
  "& .MuiInputBase-input": {
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    border: "0.5px solid #000000",
    borderRadius: "5px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      // width: "20ch",
    },
  },
}));

export default function Header() {
  const navigate = useNavigate();
  const cartCount = useSelector((state) => state.cart.cartCount);

  const matches = useMediaQuery("(max-width:745px)");
  const settings = [
    {
      menu: "Dashboard",
      url: "/dashboard",
      icon: <DashboardIcon sx={{ mr: "10px", width: "20px" }} />,
    },
    {
      menu: "Certificate",
      url: "/certificates",
      icon: <HistoryEduIcon sx={{ mr: "10px", width: "20px" }} />,
    },
    {
      menu: "Wishlist",
      url: "/whishlist",
      icon: <FavoriteIcon sx={{ mr: "10px", width: "20px" }} />,
    },
    {
      menu: "Profile",
      url: "/profile",
      icon: <PersonIcon sx={{ mr: "10px", width: "20px" }} />,
    },
    // {
    //   menu: "Settings",
    //   url: "/settings",
    //   icon: <SettingsIcon sx={{ mr: "10px", width: "20px" }} />,
    // },
    {
      menu: "Membership",
      url: "/membership",
      icon: <RocketLaunchIcon sx={{ mr: "10px", width: "20px" }} />,
    },
  ];

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const [search, setSearch] = useState([]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    sessionStorage.clear();
  };

  const handleCart = () => {
    navigate("/checkout");
    // dispatch(getCartDetails());
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    // const searchInputData = {
    //   page: 0,
    //   search: e.target.value,
    // };
    // console.log(e.target.value, searchInputData);
    if (e.key === "Enter") {
      // dispatch(getSearchCourse(searchInputData));
      navigate(`/dashboard?search=${e.target.value}`);
    } else {
    }
  };

  // const searchRedirect = (id) => {
  //   navigate(`/preview-course/${id}`);
  // };
  return (
    <Box sx={{ flexGrow: 1, justifyContent: "space-between" }}>
      <AppBar
        sx={{
          backgroundColor: "white",
          height: "114.81px",

          display: "flex",
          justifyContent: "space-between",
          flexGrow: 1,
        }}
        position="static"
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "114.81px",
            flexGrow: 1,
          }}
        >
          <ImageListItem
            sx={{ cursor: "pointer", marginLeft: "30px", marginRight: "30px" }}
          >
            <img src={Logo} alt={"logo"} loading="lazy" />
          </ImageListItem>

          <Search>
            <SearchIconWrapper>
              <img src={Searchicon} alt="SearchIcon" />
            </SearchIconWrapper>

            <StyledInputBase
              sx={{ backgroundColor: "transparent" }}
              placeholder="Want to learn?"
              inputProps={{ "aria-label": "search" }}
              type="text"
              name="searchData"
              className="input-searchbar"
              onKeyPress={handleSearch}
            />
          </Search>

          {!matches && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "50px",
                justifyContent: "center",
                flexGrow: 1,
                cursor: "pointer",
              }}
            >
              {navbardata.map((value, index) => (
                <Link
                  variant="a"
                  href={value.Link}
                  key={index}
                  sx={{
                    color: "#3A3A3A",
                    fontSize: "16px",
                    fontWeight: "400",
                    textDecoration: "none",
                  }}
                >
                  {value.title}
                </Link>
              ))}
            </Box>
          )}
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              flexGrow: 0.1,
              paddingLeft: "90px",
            }}
          >
            <Box sx={{ position: "relative" }}>
              <Tooltip title="Open Cart">
                <IconButton
                  size="large"
                  aria-label="show 4 new mails"
                  color="inherit"
                  onClick={handleCart}
                >
                  <ShoppingCartIcon sx={{ color: "#000" }} />
                </IconButton>
              </Tooltip>
              <Typography
                sx={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                  background: "#6B74D8",
                  borderRadius: "50%",
                  width: "20px",
                  height: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "14px",
                }}
              >
                {cartCount?.cartCount}
              </Typography>
            </Box>
            <Tooltip title="Open notification">
              <IconButton
                size="large"
                aria-label="show 4 new mails"
                color="inherit"
                onClick={handleOpenNavMenu}
              >
                <img src={NotificationIcon} alt="" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Open Whishlist">
              <IconButton
                size="large"
                aria-label="show 4 new mails"
                color="inherit"
                onClick={() => navigate("/whishlist")}
              >
                <FavoriteBorderIcon sx={{ color: "#000" }} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              <MenuItem
                onClick={handleCloseUserMenu}
                sx={{ width: "250px", p: "10px 30px" }}
              >
                <Typography textAlign="center">No Notification </Typography>
              </MenuItem>
            </Menu>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <img src={AvatorIcon} alt="" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting, index) => (
                <Link
                  key={index}
                  color="inherit"
                  href={setting.url}
                  sx={{ textDecoration: "none" }}
                >
                  <MenuItem
                    key={setting}
                    onClick={handleCloseUserMenu}
                    sx={{ p: "10px 30px" }}
                  >
                    {setting.icon}
                    <Typography textAlign="center">{setting.menu}</Typography>
                  </MenuItem>
                </Link>
              ))}
              <Link
                color="inherit"
                sx={{ textDecoration: "none" }}
                href="/login"
                onClick={handleLogout}
              >
                <MenuItem onClick={handleCloseUserMenu} sx={{ p: "10px 30px" }}>
                  <LogoutIcon sx={{ mr: "10px", width: "20px" }} />
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              </Link>
            </Menu>
          </Box>
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <MenuIcon />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
