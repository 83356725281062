import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import OptionsTextBox from "../../TextEditor/OptionsTextBox";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const QuestionTypeCheckBox = ({
  commentAns,
  setCommentAns,
  questionData,
  optionValue,
  setOptionValue,
}) => {
  //   useEffect(() => {
  //     setCorrectAns({
  //       A: false,
  //       B: false,
  //       C: false,
  //       D: false,
  //     });
  //   }, []);

  return (
    <>
      {/* <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid
          xs={12}
          style={{
            marginTop: 3,
            marginLeft: 10,
          }}
        >
          {" "}
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
              <Typography
                style={{
                  marginLeft: 5,
                  fontWeight: "bold",
                }}
              >
                checkbox :
              </Typography>
            </FormLabel>
          </FormControl>
        </Grid>

        {optionsLable?.map((opt, index) => {
          return (
            <Grid
              xs={6}
              key={index}
              style={{
                marginTop: 8,
              }}
            >
              <Stack direction="row">
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{
                        marginRight: 8,
                        marginLeft: -5,
                      }}
                      //checked={letter[opt]}
                      onChange={(e) => {
                        console.log(correctAns);
                        setCorrectAns({
                          ...correctAns,
                          [e.target.name]: e.target.checked,
                        });
                      }}
                      name={letter[opt]}
                    />
                  }
                  label={letter[opt]}
                  labelPlacement="start"
                />{" "}
                <Box
                  style={{
                    width: "100%",
                  }}
                >
                  {correctAns == letter[opt] && (
                    <Stack direction="row">
                      <CheckCircleIcon
                        color="success"
                        fontSize="small"
                      ></CheckCircleIcon>
                      <Typography color="success" variant="subtitle1">
                        Correct Ans
                      </Typography>
                    </Stack>
                  )}
                  <OptionsTextBox
                    label={letter[opt]}
                    index={index}
                    optionsData={optionsData}
                    setoptionsData={setoptionsData}
                  ></OptionsTextBox>
                </Box>
                <CancelIcon
                  sx={{
                    color: "#ff7b3d",
                    cursor: "pointer",
                  }}
                  fontSize="small"
                  onClick={() => {
                    if (optionsLable.length > 2) {
                      let temp = [...optionsLable];

                      temp.pop();
                      setOptionsLable(temp);

                      let valu = correctAns;
                      let keys = Object.keys(valu);
                      delete valu[keys[keys.length - 1]];
                      setCorrectAns({
                        ...valu,
                      });
                    } else {
                    }
                  }}
                ></CancelIcon>
              </Stack>
            </Grid>
          );
        })}

        <Grid xs={12} sx={{ marginTop: 3, marginLeft: 5 }}>
          <Stack direction="row" spacing={2}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                if (optionsLable.length < 10) {
                  let temp = [...optionsLable];
                  temp.push(optionsLable.length);
                  setOptionsLable(temp);
                  setCorrectAns({
                    ...correctAns,
                    [letter[optionsLable.length]]: false,
                  });
                } else {
                }
              }}
              startIcon={<AddCircleIcon></AddCircleIcon>}
            >
              Add Option
            </Button>

            <Typography> </Typography>
          </Stack>
        </Grid>
      </Grid> */}
    </>
  );
};

export default QuestionTypeCheckBox;
