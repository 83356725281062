import React, { lazy, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useParams,
} from "react-router-dom";
import CertificateDesignOne from "../components/Certificate/CertificateDesignOne";
import CertificateDesignThree from "../components/Certificate/CertificateDesignThree";
import CertificateDesignTwo from "../components/Certificate/CertificateDesignTwo";
import OAuth2RedirectHandler from "../Pages/Account/OAuth2RedirectHandler";
import TransactionFailed from "../Pages/TransactionFailed";
import QuizPage from "../components/UserDashBoard/QuizPage";
import jwt_decode from "jwt-decode";
import EnrollSuccess from "../Pages/EnrollSuccess";

const Login = lazy(() => import("../Pages/Account/Login"));
const Whislist = lazy(() => import("../Pages/Wishlist/Whislist"));
const AdminRatings = lazy(() => import("../Pages/Admin/AdminRatings"));
const AdminEvents = lazy(() => import("../Pages/Admin/AdminEvents"));
const ResetPassword = lazy(() => import("../Pages/ResetPassword"));
const Membership = lazy(() => import("../Pages/Membership/Membership"));
const Quiz = lazy(() => import("../Pages/Assessment/quiz"));
const Result = lazy(() => import("../Pages/Assessment/Result"));
const ThankYou = React.lazy(() => import("../Pages/ThankYou"));
const Checkout = React.lazy(() => import("../Pages/Checkout/Checkout"));
const Dashboard = React.lazy(() => import("../Pages/Dashboard/Dashboard"));
const PreviewCourse = React.lazy(() =>
  import("../Pages/PreviewCourse/PreviewCourse")
);
const Survey = React.lazy(() => import("../Pages/Survey/Survey"));
const Profile = React.lazy(() => import("../Pages/Profile/Profile"));
const YourSettings = React.lazy(() =>
  import("../Pages/YourSettings/YourSettings")
);
const Admin = lazy(() => import("../Pages/Admin/Admin"));
const AdminDashboard = lazy(() => import("../Pages/Admin/AdminDashboard"));
const AdminManageCourse = lazy(() =>
  import("../Pages/Admin/AdminManageCourse")
);
const AdminMarketing = lazy(() => import("../Pages/Admin/AdminMarketing"));
const AdminQandA = lazy(() => import("../Pages/Admin/AdminQandA"));
const AdminQuiz = lazy(() => import("../Pages/Admin/AdminQuiz"));

const AssessmentDashboard = lazy(() =>
  import("../Pages/Assessment/AssessmentDashboard")
);
const Certificates = lazy(() => import("../Pages/Certificates/Certificates"));

const routeLogic = (roles) => {
  console.log(roles + "  roles");

  if (roles != null) {
    if (roles === "ADMIN") {
      return (
        <>
          <Route exact path="/admin" element={<Admin />}></Route>
          <Route
            exact
            path="/admin/dashboard"
            element={<AdminDashboard />}
          ></Route>
          <Route
            exact
            path="/admin/manageCourse"
            element={<AdminManageCourse isAdmin={true} />}
          ></Route>
          <Route
            exact
            path="/admin/qanda"
            element={<AdminQandA isAdmin={true} />}
          ></Route>
          <Route
            exact
            path="/admin/quiz"
            element={<AdminQuiz isAdmin={true} />}
          ></Route>
          <Route exact path="/admin/ratings" element={<AdminRatings />}></Route>
          <Route exact path="/admin/events" element={<AdminEvents />}></Route>

          <Route
            exact
            path="/admin/marketing"
            element={<AdminMarketing />}
          ></Route>
        </>
      );
    } else if (roles === "STAFF") {
      return (
        <>
          <Route
            exact
            path="/admin/manageCourse"
            element={<AdminManageCourse isAdmin={false} />}
          ></Route>
          <Route
            exact
            path="/admin/qanda"
            element={<AdminQandA isAdmin={false} />}
          ></Route>
          <Route
            exact
            path="/admin/quiz"
            element={<AdminQuiz isAdmin={false} />}
          ></Route>
          <Route exact path="/admin/ratings" element={<AdminRatings />}></Route>
          <Route exact path="/admin/events" element={<AdminEvents />}></Route>
          <Route
            exact
            path="/admin/marketing"
            element={<AdminMarketing />}
          ></Route>
        </>
      );
    } else {
      return (
        <>
          <Route
            exact
            path="/whishlist"
            element={<Whislist></Whislist>}
          ></Route>
          <Route exact path="/dashboard" element={<Dashboard />}></Route>
          <Route
            exact
            path="/preview-course/:courseId"
            element={<PreviewCourse />}
          ></Route>
          <Route exact path="/quiz/:id" element={<Quiz />}></Route>
          <Route exact path="/survey" element={<Survey />}></Route>
          <Route exact path="/result" element={<Result></Result>}></Route>
          <Route
            exact
            path="/assessment"
            element={<AssessmentDashboard />}
          ></Route>
          <Route exact path="/checkout" element={<Checkout />}></Route>
          <Route exact path="/profile" element={<Profile />}></Route>
          <Route exact path="/settings" element={<YourSettings />}></Route>
          <Route exact path="/membership" element={<Membership />}></Route>

          <Route path={`/thankyou`} element={<ThankYou />}></Route>
          <Route
            path={`/thank-you-for-enrolling`}
            element={<EnrollSuccess />}
          ></Route>
          <Route
            path={`/transactionfailed`}
            element={<TransactionFailed />}
          ></Route>
          <Route exact path="/certificates" element={<Certificates />}></Route>
        </>
      );
    }
  } else {
    return (
      <>
        <Route
          exact
          path="/"
          element={<Navigate from="/" to="/login"></Navigate>}
        ></Route>
        <Route exact path="/login" element={<Login />}></Route>
      </>
    );
  }
};

function MainRouter(props) {
  // const dispatch = useDispatch();
  // const notification = useSelector((state) => state.notification);

  return (
    <Router>
      {/* <Notification
        type={notification.type}
        open={notification.Notification}
        message={notification.message}
        handleClose={() => {
          dispatch(offNotification());
        }}
      ></Notification> */}
      <React.Suspense fallback={<h1>Loding...</h1>}>
        <Routes>
          {console.log("mainroute" + sessionStorage.getItem("token"))}
          {("sessionStorage", console.log(sessionStorage.getItem("token")))}
          {sessionStorage.getItem("token") != null &&
            (jwt_decode(sessionStorage.getItem("token"))?.role
              ? routeLogic(jwt_decode(sessionStorage.getItem("token"))?.role)
              : routeLogic(null))}

          <Route exact path="/login" element={<Login />}></Route>
          <Route
            exact
            path="/resetpassword"
            element={<ResetPassword />}
          ></Route>

          <Route
            path="/oauth2/redirect"
            element={<OAuth2RedirectHandler />}
          ></Route>
          <Route exact path="*" element={<Login />} />
        </Routes>
      </React.Suspense>
    </Router>
  );
}

export default MainRouter;
