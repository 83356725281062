import API from "./API";

export const getQnAAPI = (courseId) => {
  return API.get(`/qanda/${courseId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getAllQnAAPI = () => {
  return API.get(`/qandaall`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const addQnAAPI = (payload) => {
  return API.post(`/qanda/addquestion`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateQnAAPI = (payload) => {
  return API.put(`/qanda/addanswer`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateQnAApproveAPI = (payload) => {
  return API.put("/qanda/approve", null, {
    params: {
      ...payload,
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

// export const updateQnAApproveAPI = ({ approvalFlag, qandaModelid }) => {
//   console.log("payload update", approvalFlag, qandaModelid);

//   return API.put(
//     `/qanda/approve?approvalFlag=${approvalFlag}&qandaModelid=${qandaModelid}`
//   )
//     .then((response) => {
//       return response;
//     })
//     .catch((error) => {
//       console.log(error);
//       return error;
//     });
// };
