import API from "./API";

export const signupAPI = (payload) => {
  return API.post("/auth/signup", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const loginAPI = (payload) => {
  return API.post("/auth/login", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("err", error);
      return error.response;
    });
};

export const forgetPasswordAPI = (payload) => {
  return API.put(`/auth/forgotpassword?&email=${payload.email}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("err", error);
      return error.response;
    });
};
