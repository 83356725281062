import React from "react";
import { useState, useEffect } from "react";

const Timer = (props) => {
  const {
    initialMinute = 0,
    initialSeconds = 0,
    timeoutMethod,
    messageModal,
  } = props;
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          timeoutMethod();
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  useEffect(() => {
    if (messageModal) {
      setSeconds(initialSeconds);
      setMinutes(initialMinute);
    }
  }, [messageModal]);

  return (
    <>
      {minutes === 0 && seconds === 0 ? null : (
        <p
          style={{
            fontWeight: "bold",
          }}
        >
          {" "}
          {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </p>
      )}
    </>
  );
};

export default Timer;
