import { Box, IconButton, InputAdornment, OutlinedInput, Typography } from '@mui/material'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close';

export function Heading1({text}){
    return (
        <Typography variant="h1" sx={{fontSize: "36px", fontWeight: "600", color: "#101828"}}>{text}</Typography>
    )
}

export function Heading2({text}){
    return (
        <Typography variant="h2" sx={{fontSize: "26px", fontWeight: "600", color: "#101828"}}>{text}</Typography>
    )
}

export function Heading3({text}){
    return (
        <Typography variant="h3" sx={{fontSize: "20px", fontWeight: "400", color: "#000"}}>{text}</Typography>
    )
}


export function Subtitle1({text}){
    return (
        <Typography variant="body1" sx={{fontSize: "20px", fontWeight: "400", color: "#667085"}}>{text}</Typography>
    )
}

export function Subtitle2({text, colorCustom}){
    return (
        <Typography variant="body1" sx={{fontSize: "14px", fontWeight: "500", color: colorCustom ? colorCustom : "#344054"}}>{text}</Typography>
    )
}




