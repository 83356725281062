import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addAnswerAPI, updateAnswerAPI, getAnswerAPI } from "../api/AnswerApi";

const initialState = {
  answer: [],
  answerFlag: false,
};

export const addAnswer = createAsyncThunk(
  "answer/addAnswer",
  async (payload) => {
    const res = await addAnswerAPI(payload);
    return res.data;
  }
);

export const updateAnswer = createAsyncThunk(
  "answer/updateUser",
  async (payload) => {
    const res = await updateAnswerAPI(payload);
    return res.data;
  }
);

const answerSlice = createSlice({
  name: "answer",
  initialState,
  extraReducers: (builder) => {
    //add user
    builder.addCase(addAnswer.pending, (state, action) => {
      state.answerFlag = false;
    });
    builder.addCase(addAnswer.fulfilled, (state, action) => {
      if (state.answer?.length > 0) {
        state.answer.push(action.payload.response);
      } else {
        state.answer = [action.payload.response];
      }
      state.answerFlag = true;
    });
    builder.addCase(addAnswer.rejected, (state, action) => {
      state.answerFlag = false;
    });

    //update user
    builder.addCase(updateAnswer.pending, (state, action) => {
      state.answerFlag = false;
    });
    builder.addCase(updateAnswer.fulfilled, (state, action) => {
      state.answer = state.answer.map((value) => {
        return value?.canditateentry?.candiateid ===
          action?.payload?.response?.canditateentry?.candiateid &&
          value?.question?.questionid ===
            action?.payload?.response?.question?.questionid
          ? action?.payload?.response
          : value;
      });
      state.answerFlag = true;
      console.log(state.answer);
    });
    builder.addCase(updateAnswer.rejected, (state, action) => {
      state.answerFlag = false;
    });
  },
});

export default answerSlice.reducer;
