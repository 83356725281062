import React, { useState } from "react";
import ReactQuill from "react-quill";
import hljs from "highlight.js";

hljs.configure({
  languages: ["javascript", "ruby", "python", "rust", "java"],
});

function ViewTextBox({ value }) {
  return (
    <ReactQuill
      // style={{ width: "70%" }}
      theme={null}
      readOnly={true}
      value={value}
    ></ReactQuill>
  );
}

export default ViewTextBox;
