import API from "./API";

export const addToCartAPI = (courseId) => {
  return API.post(`/addtocart?courseId=${courseId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getCartAPI = () => {
  return API.get("/addtocart")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteCartAPI = (courseId) => {
  return API.delete(`/addtocart?courseId=${courseId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getCartCountAPI = () => {
  return API.get("/addtocartcount")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
