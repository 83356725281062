import { Box, Button } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import Header from "../../components/Header";
import ViewTextBox from "../TextEditor/ViewTextBox";
import Timer from "./Timer";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import Typography from "@mui/material/Typography";
import QuestionType from "./QuestionTypes/QuestionType";
import Pagination from "@mui/material/Pagination";
import PerfectScroll from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

function QuizPage({
  commentAns,
  setCommentAns,
  questionData,
  test,
  currentQuestion,
  setCurrentQuestion,
  endTestMethod,
  skipQuestion,
  setskipQuestion,
  markedQuestion,
  setmarkedQuestion,
  addAnswerMethod,
  updateAnswerMethod,
  messageFlag,
  setMessageFlag,
  optionValue,
  setOptionValue,
  rfInstance,
  setRfInstance,
  nodes,
  setNodes,
  onNodesChange,
  edges,
  setEdges,
  onEdgesChange,
}) {
  var questions = [],
    i = 0,
    len = test?.test?.quiz?.noofquestion;
  while (++i <= len) questions.push(i);

  const navigationColor = (v) => {
    if (currentQuestion == v) {
      return "current";
    } else if (markedQuestion.includes(v)) {
      return "marked";
    } else if (skipQuestion.includes(v)) {
      return "skip";
    } else {
      return "";
    }
  };

  const onSave = useCallback(() => {
    console.log(rfInstance);
    if (rfInstance) {
      const flow = rfInstance.toObject();
      console.log(JSON.stringify(flow));
      // setCorrectAns(JSON.stringify(flow?.edges));
      // setoptionsData(JSON.stringify(flow?.nodes));
      const nodes = flow?.nodes;
      const edges = flow?.edges;
      const final = {
        nodes: nodes,
        edges: edges,
      };
      return JSON.stringify(final);
    }
  }, [rfInstance]);

  return (
    <>
      <Container>
        <div className="header">
          <p>{test?.test?.quiz?.quizname}</p>
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              gap: "0.5rem",
              alignItems: "center",
            }}
          >
            <svg
              width="29.250000"
              height="29.250000"
              viewBox="0 0 29.25 29.25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.6108 0C6.53906 0 0 6.55313 0 14.625C0 22.6969 6.53906 29.25 14.6108 29.25C22.6968 29.25 29.25 22.6969 29.25 14.625C29.25 6.55313 22.6968 0 14.6108 0ZM14.625 26.325C8.16309 26.325 2.9248 21.0867 2.9248 14.625C2.9248 8.16328 8.16309 2.925 14.625 2.925C21.0869 2.925 26.3252 8.16328 26.3252 14.625C26.3252 21.0867 21.0869 26.325 14.625 26.325Z"
                fillRule="evenodd"
                fill="#5453B4"
              />
              <path
                d="M15.3564 7.3125L13.1626 7.3125L13.1626 16.0875L20.8408 20.693L21.9375 18.893L15.3564 14.9906L15.3564 7.3125Z"
                fillRule="evenodd"
                fill="#5453B4"
              />
              <defs />
            </svg>
            <Timer
              initialMinute={test?.test?.quiz?.quiztime}
              timeoutMethod={() => {
                endTestMethod();
              }}
            ></Timer>
          </div>
          <Box sx={{ pr: "20px" }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#6B74D8",
                color: "#fff",
                "&.MuiButtonBase-root:hover": {
                  backgroundColor: "#6B74D820",
                },
              }}
              onClick={() => {
                addAnswerMethod({
                  canditateId: test?.test?.candiateid,
                  questionId: questionData?.questionid,
                  optionId: optionValue,
                  quizId: test?.test?.quiz?.quizid,
                  commentanswer:
                    questionData?.questiontypes == 7 ? onSave() : commentAns,
                });
                endTestMethod();
              }}
            >
              End test
            </Button>
          </Box>
        </div>
        <div className="body">
          {/* <progress value={50} max={100} /> */}

          <div className="question_header">
            Question {currentQuestion}&nbsp;:&nbsp;
          </div>

          <div className="question">
            <div
              style={{
                marginLeft: 10,
              }}
            >
              <ViewTextBox
                value={
                  questionData?.question && JSON.parse(questionData?.question)
                }
              ></ViewTextBox>
            </div>
            {/* <hr
              style={{
                width: "60%",
                color: "grey",
              }}
            ></hr> */}
          </div>
          <div className="optionsset">
            <QuestionType
              commentAns={commentAns}
              setCommentAns={setCommentAns}
              questionData={questionData}
              optionValue={optionValue}
              setOptionValue={setOptionValue}
              currentQuestion={currentQuestion}
              rfInstance={rfInstance}
              setRfInstance={setRfInstance}
              nodes={nodes}
              setNodes={setNodes}
              onNodesChange={onNodesChange}
              edges={edges}
              setEdges={setEdges}
              onEdgesChange={onEdgesChange}
            ></QuestionType>
          </div>

          <div className="question-navigation">
            {/* <div className="header">Question Navigation</div> */}
            <div className="buttons">
              <div style={{ margin: 5, marginTop: 20 }}>
                <Button
                  variant="contained"
                  onClick={() => {
                    setCurrentQuestion(currentQuestion + 1);
                    const skip = [...skipQuestion];
                    skip.push(currentQuestion);
                    setskipQuestion(skip);
                    setCommentAns("");
                    setRfInstance(null);
                    setNodes([]);
                    setEdges([]);
                    setOptionValue(0);
                  }}
                  sx={{ backgroundColor: "#D0D5DD" }}
                >
                  Skip
                </Button>
              </div>
              {/* {questions?.length > 0 && (
                <Pagination
                  count={questions?.length}
                  variant="outlined"
                  shape="rounded"
                />
              )} */}
              <PerfectScroll
                style={{
                  height: "150px",
                }}
              >
                <ul className="options">
                  {questions?.length > 0 &&
                    questions?.length <= 100 &&
                    questions?.map((v, index) => (
                      <li
                        key={index}
                        className={navigationColor(v)}
                        onClick={() => {
                          setCurrentQuestion(v);
                        }}
                      >
                        {v}
                      </li>
                    ))}
                </ul>
              </PerfectScroll>

              <div style={{ margin: 5 }}>
                <Button
                  style={{ margin: 5 }}
                  variant="contained"
                  color="primary"
                  startIcon={<ArrowBackIcon></ArrowBackIcon>}
                  onClick={() => {
                    currentQuestion > 1 &&
                      setCurrentQuestion(currentQuestion - 1);
                  }}
                >
                  Prev
                </Button>
                <Button
                  style={{ margin: 5 }}
                  variant="contained"
                  color="primary"
                  disabled={questions?.length <= currentQuestion}
                  endIcon={<ArrowForwardIcon></ArrowForwardIcon>}
                  onClick={() => {
                    if (
                      optionValue == null &&
                      optionValue == undefined &&
                      commentAns == ""
                    ) {
                      setCurrentQuestion(currentQuestion + 1);
                      const skip = [...skipQuestion];
                      skip.push(currentQuestion);
                      setskipQuestion(skip);
                    } else {
                      setCurrentQuestion(currentQuestion + 1);
                      const marked = [...markedQuestion];
                      marked.push(currentQuestion);
                      setmarkedQuestion(marked);
                      console.log(questionData?.questiontypes);
                      const commentAnswer =
                        questionData?.questiontypes == 7
                          ? onSave()
                          : commentAns;
                      console.log(commentAnswer);
                      if (markedQuestion.includes(currentQuestion)) {
                        updateAnswerMethod({
                          canditateId: test?.test?.candiateid,
                          questionId: questionData?.questionid,
                          optionId: optionValue,
                          quizId: test?.test?.quiz?.quizid,
                          commentanswer: commentAnswer,
                        });
                      } else {
                        addAnswerMethod({
                          canditateId: test?.test?.candiateid,
                          questionId: questionData?.questionid,
                          optionId: optionValue,
                          quizId: test?.test?.quiz?.quizid,
                          commentanswer: commentAnswer,
                        });
                      }
                      setCommentAns("");
                      setNodes([]);
                      setEdges([]);
                      setRfInstance(null);
                      setOptionValue(0);
                    }
                  }}
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

const Container = styled.div`
  position: relative;
  min-height: 720px;
  height: 100%;
  background-color: #f8f8f8;

  .header {
    background-color: #fff;
    width: 100%;
    /* position: absolute; */
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000;
    font-weigth: bold;
  }

  .body {
    padding: 1rem;
    progress {
      width: 100%;
      accent-color: #5453b4;
    }
  }

  .question {
    border: 1px solid #fff;
    border-radius: 8px;
    border-width: 7px;
    position: relative;
    height: 100%;
    background-color: #fff;
    color: #000;
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.15);
  }

  .optionsset {
    margin-top: 10px;
    border: 1px solid #fff;
    border-radius: 8px;
    border-width: 7px;

    height: 100%;
    background-color: #fff;
    color: #000;
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.15);
  }
  .question_header {
    padding: 1rem;
    margin-bottom: 1rem;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 8px;
    color: #000;
    width: 20%;
  }

  .optionsset ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
  }
  .optionsset ul > li {
    flex-basis: 50%;
    max-height: 60px;
  }

  .question-navigation {
    border: 1px solid #fff;
    background-color: #fff;
    border-radius: 8px;
    position: absolute;
    bottom: -60px;
    width: 98%;
  }

  .question-navigation .header {
    padding: 1rem;
    background-color: #fff;
    color: #000;
  }
  .options {
    display: flex;
    flex-wrap: wrap;
  }

  .question-navigation ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 0.3rem;
    padding: 0.3rem;
    justify-content: center;
  }

  .question-navigation ul > li {
    padding: 5px 12px;
    background: #8798ad;
    color: #fff;
    cursor: pointer;
    border-radius: 8px;
  }

  .question-navigation ul > li.current {
    // border: 3px groove #505a66;
    background: #485464;
  }
  .question-navigation ul > li.skip {
    background: #e39e54;
  }

  .question-navigation ul > li.marked {
    background: #39b271;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    align-items: left;
  }

  .buttons button {
    padding: 0.5rem 2rem;
    border-radius: 8px;
  }

  .buttons button + button {
    margin-left: 1rem;
  }

  @media (max-width: 48rem) {
    .buttons {
      flex-direction: column-reverse;
      gap: 1rem;
    }
  }
`;

export default QuizPage;
