import API from "./API";

export const getSyllabusAPI = (id) => {
  return API.get(`/syllabus/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const addSyllabusAPI = (payload) => {
  return API.post("/syllabus", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const updateSyllabusAPI = (payload) => {
  return API.put("/syllabus", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const deleteSyllabusAPI = (id) => {
  return API.delete(`/syllabus/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};
